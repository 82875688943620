<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <h2 class="brand-text">パスワード再設定</h2>

        <!----------------------------------------------- FORM -------------------------------------------------------->
        <validation-observer ref="resetPasswordForm">
          <b-form @submit.prevent="validation">
            <validation-provider
              #default="{ errors }"
              name="password"
              vid="password"
            >
              <b-form-group label-for="password">
                <template #label>
                  <div class="label-container d-flex">
                    <p class="label-detail label__detail-left">
                      新規パスワード
                    </p>
                    <p class="label-detail label__form-right">
                      ※半角英数字の組合せ（8桁以上）
                    </p>
                  </div>
                </template>
                <b-input-group
                class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    class="form-control-merge no-icon"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    />
                    <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePwVisibility('pw')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="password_confirmation"
              vid="password_confirmation"
            >
              <b-form-group
                label-for="confirmPassword"
                label="新規パスワード（確認）"
              >
                <template #label>
                  <div class="label-container d-flex">
                    <p class="label-detail label__detail-left">
                      新規パスワード（確認）
                    </p>
                    <p class="label-detail label__form-right">
                      ※半角英数字の組合せ（8桁以上）
                    </p>
                  </div>
                </template>
                <b-input-group
                class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="form.password_confirmation"
                    class="form-control-merge no-icon"
                    :type="passwordConfirmType"
                    :state="errors.length > 0 ? false : null"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordConfrimToggleIcon"
                      @click="togglePwVisibility('confirmPw')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- submit button -->
            <div class="btn__setPw-wrap">
              <b-button class="btn__setPw" type="submit"> 再設定 </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { STATUS } from "@/constant/constant";
import { apiService } from "@/services/api.service";
import { required } from "@validations";
import {
  BButton,
  BCard,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      passwordFieldType: "password",
      passwordConfirmType: "password",
      form: {
        password: "",
        password_confirmation: "",
      },
      token: "",
    };
  },
  created() {
    this.onCheckToken();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
    passwordConfrimToggleIcon() {
      return this.passwordConfirmType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
  },
  methods: {
    togglePwVisibility(type) {
      if (type === "pw")
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      else
        this.passwordConfirmType =
          this.passwordConfirmType === "password" ? "text" : "password";
    },

    validation() {
      this.$refs.resetPasswordForm.validate().then((success) => {
        if (success) {
          this.onResetPassword();
        }
      });
    },
    async onCheckToken() {
      this.startLoading();
      try {
        const response = await apiService.post("auth/password/check-token", {
          token: this.$route.params.token,
        });
        const status = response.data.code;
        this.endLoading();
        if (status === STATUS.SUCCESS) {
          // nothing
        } else {
          this.notifyError(response.data.message);
          this.$router.push({ name: "login" });
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error);
      }
    },
    async onResetPassword() {
      this.startLoading();
      try {
        const response = await apiService.post("auth/password/reset", {
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          token: this.$route.params.token,
        });
        const status = response.data.code;
        this.endLoading();
        if (status === STATUS.SUCCESS) {
          this.$router.push({ name: "reset-password-success" });
        } else if (status === STATUS.BAD_REQUEST) {
          this.$refs.resetPasswordForm.setErrors(response.data.data);
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";

.brand-text {
  font-family: Noto Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
}

.btn__setPw-wrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  button {
    width: 200px;
  }
}

.btn__setPw {
  background: #377dff !important;
  border: none;
}

.label__form-right {
  float: right;
  font-size: 14px;
  color: #5c5c5c;
  font-family: "Noto Sans";
  font-style: normal;
}

.label-container {
  justify-content: space-between;

  .label-detail {
    margin-bottom: 0;
  }

  .label__detail-left {
    font-size: 14px;
    font-weight: 600;
  }

  .label__form-right {
    font-size: 12px;
    color: #5c5c5c;
    font-weight: 300;
  }
}
</style>
q
